
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapMutations } from 'vuex';
import { AppStore } from '@interface/store/App';
import { handle429 } from '@util/util.ts';
import { app } from '@config';
import CardDefault from '@part/elements/CardDefault.vue';
import CardFull from '@part/elements/CardFull.vue';
import InputField from '@part/elements/InputField.vue';
import Pearls from '@part/elements/Pearls.vue';
import Overlay from '@part/layout/Overlay.vue';
import FilterMenu from '@part/elements/FilterMenu.vue';
import FiltersWhiteSvg from '@svg/FiltersWhite.vue';
import SurpriseSvg from '@svg/Surprise.vue';
import axios from 'axios';
import ArrowOpenSvg from '@svg/ArrowOpen.vue';
import Dropdown from '@part/elements/Dropdown.vue';

/* -------------  ---------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    Dropdown,
    CardDefault,
    CardFull,
    InputField,
    FilterMenu,
    Overlay,
    Pearls,
    FiltersWhiteSvg,
    SurpriseSvg,
    ArrowOpenSvg
  },
  props: {
    setOverlayStatus: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      animating: true,
      filtered: false,
      overlayVisible: false,
      loadingEventData: true,
      filterData: {},
      eventData: {},
      pearls: {},
      visibleEvents: 0,
      adverts: [],
      pageData: null,
      maxEvents: 22,
      filtersHeight: 0,
      isFiltering: false
    };
  },
  computed: {
    ...mapState(['selectedFilters', 'filteredEvents', 'selectedAmbassador']),

  },
  created() {
    // reset ambassador filters
    this.resetAmbassadorFilters();
  },
  methods: {
    ...mapMutations([
      'selectAmbassadorFilter',
      'addToFilters',
      'resetFilters',
      'resetAmbassadorFilters',
      'filterEvents',
      'filterEventArr',
      'setUnfilteredEvents'
    ]),
    animate() {
      this.animating = true;
      setTimeout(() => {
        this.animating = false;
      }, 500);
    },
    getGridClass(index) {
      if (index % 5 < 2) {
        return 'event-grid__item--span-3';
      } else if (index % 5 < 4) {
        return 'event-grid__item--span-2';
      }
      return 'event-grid__item--add';
    },
    getAdvert(index) {
      const adIndex = Math.floor(index / 5);
      return this.adverts[adIndex % this.adverts.length];
    },
    toggleOverlay() {
      this.overlayVisible = !this.overlayVisible;
    },
    closeOverlay() {
      this.overlayVisible = false;
    },
    loadMore() {
      this.visibleEvents += this.maxEvents;
      // rounds number so that it adds either 3 or 5 items, depending on the grid
      let roundTo = this.filtered ? 3 : 5;
      this.visibleEvents = Math.round(this.visibleEvents / roundTo) * roundTo;
    },
    filtering(filtering) {
      this.isFiltering = filtering;
      this.loadingEventData = filtering;
    },
    handleAmbassador(data) {
      this.animate();
      this.filtered = true;

      // you can only select 1 ambassador, so it resets if you pick a new one
      if (this.selectedAmbassador.id !== data.filterId) {
        this.resetFilters();
      }

      this.selectAmbassadorFilter({ id: data.filterId, index: data.i });
      this.addToFilters({ type: data.type, filterId: data.filterId });

      this.filterEventArr(this.eventData);

    },
    handleFilter(data) {
      this.animate();
      this.filtered = true;

      // you can't combine ambassador filters with the rest of the fitlers, so filtering the rest clears ambassadors
      this.resetAmbassadorFilters();
      this.addToFilters({ type: data.type, filterId: data.filterId });
      this.filterEvents(this.eventData);
    },
    resetClicked(data) {
      this.animate();
      this.resetFilters();
      this.filterEvents(this.eventData);
    },
    countFiltersSelected() {
      let count = 0;
      for (let key in this.selectedFilters) {
        count += this.selectedFilters[key].length;
      }
      return count;
    },
    onSearch() {
      this.filtered = true;
    },
    toggleFilters() {
      if (this.filtersHeight === 0) {
        this.filtersHeight = 700;
        return;
      }
      this.filtersHeight = 0;
    }
  },
  mounted() {
    this.loadMore();

    // ///// QUICKFIX /////////
    document.title = 'Tentoonstellingen | Museumnacht Amsterdam';
    // /////////////////////////

    // request for event data
    axios
      .get(`${app.api.api}/feed/exhibitions`)
      .then(response => {
        this.eventData = response.data;
        this.filterEvents(response.data);
        this.setUnfilteredEvents(response.data);
        this.loadingEventData = false;
        setTimeout(() => {
          this.animating = false;
        }, 500);
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });

    // request for filter data
    axios
      .get(`${app.api.api}/feed/filters`)
      .then(response => {
        this.filterData = response.data;
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });

    // request for hidden pearls
    axios
      .get(`${app.api.api}/feed/pearlsExhibitions`)
      .then(response => {
        this.pearls = response.data;
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });

    // request for adverts
    axios
      .get(`${app.api.api}/feed/adverts`)
      .then(response => {
        this.adverts = response.data.data;
        for (let i = this.adverts.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [this.adverts[i], this.adverts[j]] = [this.adverts[j], this.adverts[i]];
        }
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });

    axios
      .get(`${app.api.api}/page/${this.$route.meta.id}`)
      .then(response => {
        this.pageData = response.data;
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class ExhibitionOverview extends Vue { }
